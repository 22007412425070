<template>
  <div class="eagleEye1">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/eagleEyetitle.png" @click="eagleEye0" />
          <div @click="eagleEye0">首页</div>
          <div class="active" @click="eagleEye1">产品介绍</div>
          <div @click="eagleEye2">功能介绍</div>
          <div @click="eagleEye3">更新说明</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="eagleEyemain"></div>
        <div class="eagleEyetol">
          <div class="info">
            <div class="left">
              <img src="../../assets/img/tolicon.png" />
              <div class="lefttitle"><span>会员尊贵</span>上网体验！</div>
              <div class="lefttext">
                8圈鹰眼系统应用AI技术对场所内人员自动识别，并进行会员信息识别认证，为会员提供个性化服务，同时去上网APP接收到相关信息，实现快速上网。
              </div>
            </div>
            <img class="image" src="../../assets/img/tolback.png" />
          </div>
        </div>
        <div class="eagleEyeflow">
          <div class="flowtitle">上网流程</div>
          <div class="flowinfo">
            <div class="card">
              <img src="../../assets/img/flowback1.png" />
              <div class="cardtil">
                <img src="../../assets/img/flowicon1.png" />
                <div>快速识别用户</div>
              </div>
              <div class="cardtext">
                利用AI技术，对进入场所人员快速识别，并支持多人同时识别。
              </div>
            </div>
            <div class="infoimg">
              <img src="../../assets/img/flowicon4.png" />
            </div>
            <div class="card">
              <img src="../../assets/img/flowback2.png" />
              <div class="cardtil">
                <img src="../../assets/img/flowicon2.png" />
                <div>会员识别认证</div>
              </div>
              <div class="cardtext">
                快速核对会员信息，为会员提供专属上网服务。
              </div>
            </div>
            <div class="infoimg">
              <img src="../../assets/img/flowicon4.png" />
            </div>
            <div class="card">
              <img src="../../assets/img/flowback3.png" />
              <div class="cardtil">
                <img src="../../assets/img/flowicon3.png" />
                <div>极速激活上机</div>
              </div>
              <div class="cardtext">
                与去上网APP实时联动，会员快速激活上机，方便快捷 。
              </div>
            </div>
          </div>
        </div>
        <div class="eagleEyedemo">
          <div class="demotitle">应用场景演示</div>
          <div class="vio">
            <video width="960" height="540" controls="controls" preload="preload" ref="videoPlay">
              <source src="http://cdn.8quan.com/8quan/yingyan.mp4" type="video/mp4" />
            </video>
            <div class="video-img" v-show="show" @click="bofang">
              <img src="../../assets/img/bofang.png" />
            </div>
          </div>
        </div>
        <div class="eagleEyesystem">
          <div class="systemtitle">系统优势</div>
          <div class="systeminfo">
            <div class="card1">
              <img src="../../assets/img/system1.png" />
              <div class="tal">智能识别 <br />用户更好的体验感</div>
              <div class="tel">
                智能化Al识别会员极速认证，更加快速、方便完成上机，多会员到店也可完成极速识别认证
              </div>
            </div>
            <div class="card2">
              <img src="../../assets/img/system2.png" />
              <div class="tal">数据加密<br />安全可靠</div>
              <div class="tel">
                采用专用服务器，本地数据即数据传输均进行了加密，保障用户信息安全
              </div>
            </div>
            <div class="card3">
              <img src="../../assets/img/system3.png" />
              <div class="tal">性能卓越<br />系统稳定</div>
              <div class="tel">识别准确度高、速度快，24小时全天候稳定运行</div>
            </div>
          </div>
        </div>
        <div class="eagleEyeimg">
          <div class="backimg">
            <div class="backimgdiv">
              <div>产品咨询</div>
              <div>联系人</div>
              <div>李先生</div>
              <div>电话</div>
              <div>189 9633 8126</div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/yingyanfooter.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "eagleEye1",
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
  methods: {
    //点击播放视频
    bofang() {
      this.show = false;
      this.$refs["videoPlay"].play();
    },
    //跳转首页
    eagleEye0() {
      this.$router.push("/eagleEye0");
    },
    //跳转产品介绍
    eagleEye1() {
      this.$router.push("/eagleEye1");
    },
    //跳转功能介绍
    eagleEye2() {
      this.$router.push("/eagleEye2");
    },
    //跳转更新说明
    eagleEye3() {
      this.$router.push("/eagleEye3");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1200px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 16px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 148px;
      height: 48px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #626778;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #0e6eff;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #0e6eff;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0 auto;
  .eagleEyemain {
    margin: 0 auto;
    height: 640px;
    background-image: url(../../assets/img/eagleEye1back.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  .eagleEyetol {
    height: 600px;
    box-sizing: border-box;
    padding: 80px 0 0;
    .info {
      width: 1200px;
      height: 440px;
      margin: 0 auto;
      display: flex;
      .left {
        width: 440px;
        > img {
          margin-top: 20px;
        }
        .lefttitle {
          width: 361px;
          height: 54px;
          font-size: 36px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          line-height: 54px;
          color: #2d394b;
          margin-top: 20px;
          > span {
            color: #0e6eff;
          }
        }
        .lefttext {
          font-size: 24px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 40px;
          color: #626778;
          margin-top: 30px;
        }
      }
      .image {
        margin-left: 128px;
      }
    }
  }
  .eagleEyeflow {
    height: 573px;
    background: #f4f5f9;
    box-sizing: border-box;
    padding: 80px 0 0;
    .flowtitle {
      width: 805px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 54px;
      color: #2d394b;
      margin: 0 auto;
      text-align: center;
    }
    .flowinfo {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 40px auto 0;
      .card {
        width: 300px;
        .cardtil {
          display: flex;
          align-items: center;
          margin-top: 18px;
          > div {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 24px;
            color: #2d394b;
          }
        }
        .cardtext {
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 28px;
          color: #626778;
          margin-top: 10px;
        }
      }
      .infoimg {
        width: 55.97px;
        height: 16.97px;
        margin-top: 92px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .eagleEyedemo {
    height: 794px;
    box-sizing: border-box;
    padding: 80px 0 0;
    .demotitle {
      width: 310px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 54px;
      color: #2d394b;
      margin: 0 auto;
      text-align: center;
    }
    .vio {
      width: 960px;
      height: 540px;
      margin: 40px auto;
      position: relative;
      > video {
        display: inline-block;
        vertical-align: baseline;
      }
      .video-img {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 999;
        background: url("../../assets/img/eagleEyedemoback.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        > img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .eagleEyesystem {
    background: #f4f5f9;
    padding: 80px 0;
    .systemtitle {
      width: 144px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 54px;
      color: #2d394b;
      text-align: center;
      margin: 0 auto;
    }
    .systeminfo {
      width: 1040px;
      margin: 60px auto 0;
      display: flex;
      .card1,
      .card2,
      .card3 {
        width: 240px;
        text-align: center;
        .tal {
          font-size: 20px;
          line-height: 30px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #2d394b;
          margin: 20px 0 12px;
        }
        .tel {
          width: 240px;
          font-size: 16px;
          line-height: 24px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #626778;
        }
      }
      .card2 {
        margin: 0 160px;
      }
    }
  }
  .eagleEyeimg {
    height: 504px;
    box-sizing: border-box;
    padding: 80px 0 0;
    .backimg {
      width: 1200px;
      height: 344px;
      margin: 0 auto;
      background-image: url(../../assets/img/eagleEyeimg.png);
      background-size: cover;
      /* 将背景图片等比缩放填满整个容器 */
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding-top: 50px;
      .backimgdiv {
        width: 310px;
        height: 244px;
        margin-left: 68px;
        font-family: Source Han Sans SC;
        color: #ffffff;
        > div:nth-child(1) {
          width: 310px;
          height: 54px;
          font-size: 36px;
          font-weight: bold;
          line-height: 54px;
        }
        > div:nth-child(2) {
          width: 54px;
          height: 27px;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          opacity: 0.68;
          margin-top: 34px;
        }
        > div:nth-child(3) {
          width: 72px;
          height: 36px;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          margin-top: 4px;
        }
        > div:nth-child(4) {
          width: 36px;
          height: 27px;
          font-size: 18px;
          font-weight: 400;
          line-height: 18px;
          opacity: 0.68;
          margin-top: 36px;
        }
        > div:nth-child(5) {
          width: 198px;
          height: 34px;
          font-size: 28px;
          font-family: DIN;
          font-weight: 500;
          line-height: 34px;
          margin-top: 4px;
        }
      }
    }
  }
}

//底部
.el-footer {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 148px;
          height: 48px;
        }
        .text1 {
          margin-top: 37px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
